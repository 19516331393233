.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menuActive {
  background: #2c7be533 !important;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 5px !important;
  color: #2c7be5 !important;
}

label, i, table thead tr th, table thead tr th input, div {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* AC e RC */
.rc-border td:nth-child(1) {
  border-top: 2px solid var(--falcon-teal) !important;
}

.rc-border td:nth-child(7){
  border-top: 2px solid var(--falcon-teal) !important;
}

.rc-border td:nth-child(n+2):nth-child(-n+6){
  border-top: 2px solid var(--falcon-teal) !important;
}

.ac-border td:nth-child(1) {
  border-bottom: 2px solid var(--falcon-teal) !important;
}

.ac-border td:nth-child(7){
  border-bottom: 2px solid var(--falcon-teal) !important;
}

.ac-border td:nth-child(n+2):nth-child(-n+6){
  border-bottom: 2px solid var(--falcon-teal) !important;
}

/* AN e RN */
.rn-border td:nth-child(1) {
  border-top: 2px solid var(--falcon-indigo) !important;
}

.rn-border td:nth-child(7){
  border-top: 2px solid var(--falcon-indigo) !important;
}

.rn-border td:nth-child(n+2):nth-child(-n+6){
  border-top: 2px solid var(--falcon-indigo) !important;
}

.an-border td:nth-child(1) {
  border-bottom: 2px solid var(--falcon-indigo) !important;
}

.an-border td:nth-child(7){
  border-bottom: 2px solid var(--falcon-indigo) !important;
}

.an-border td:nth-child(n+2):nth-child(-n+6){
  border-bottom: 2px solid var(--falcon-indigo) !important;
}

/* AN-P e RN-P */
.rnp-border td:nth-child(1) {
  border-top: 2px solid var(--falcon-pink) !important;
}

.rnp-border td:nth-child(7){
  border-top: 2px solid var(--falcon-pink) !important;
}

.rnp-border td:nth-child(n+2):nth-child(-n+6){
  border-top: 2px solid var(--falcon-pink) !important;
}

.anp-border td:nth-child(1) {
  border-bottom: 2px solid var(--falcon-pink) !important;
}

.anp-border td:nth-child(7){
  border-bottom: 2px solid var(--falcon-pink) !important;
}

.anp-border td:nth-child(n+2):nth-child(-n+6){
  border-bottom: 2px solid var(--falcon-pink) !important;
}

/* HA e HC */
.hc-border td:nth-child(1) {
  border-top: 2px solid var(--falcon-400) !important;
}

.hc-border td:nth-child(7){
  border-top: 2px solid var(--falcon-400) !important;
}

.hc-border td:nth-child(n+2):nth-child(-n+6){
  border-top: 2px solid var(--falcon-400) !important;
}

.ha-border td:nth-child(1) {
  border-bottom: 2px solid var(--falcon-400) !important;
}

.ha-border td:nth-child(7){
  border-bottom: 2px solid var(--falcon-400) !important;
}

.ha-border td:nth-child(n+2):nth-child(-n+6){
  border-bottom: 2px solid var(--falcon-400) !important;
}